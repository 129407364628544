import { RefariDTOTypes } from '@refari-frontend/types'

type ListResponse =
  // @ts-ignore backend updates has broken the type
  RefariDTOTypes['/dashboard/company-clients/{company_client_id}/contacts/']['get']['responses']['200']['content']['application/json']

export type IClientContactListModel = ListResponse['results'][0]

export class ClientContactModel implements IClientContactListModel {
  id: IClientContactListModel['id']
  first_name: IClientContactListModel['first_name']
  last_name: IClientContactListModel['last_name']
  email: IClientContactListModel['email']
  phone: IClientContactListModel['phone']
  is_primary: IClientContactListModel['is_primary']
  constructor(data: IClientContactListModel) {
    this.id = data.id
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.email = data.email
    this.phone = data.phone
    this.is_primary = data.is_primary
  }

  get contactName(): string {
    return `${this.first_name} ${this.last_name}`
  }
}
