import { observable, action, runInAction, computed } from 'mobx'

import Logger from 'src/utils/Logger'
import { APIResponse } from 'src/types/APITypes'
import API from 'src/utils/API'
import { ApiRoutes } from 'src/utils/Urls'
import ClientsListModel, {
  type IOfficeListModel,
  type ListResponse
} from 'src/models/Offices/ListModel'
import { toast } from 'react-toastify'
import alertMessages from 'src/constants/alertMessages'
import {
  ClientContactModel,
  IClientContactListModel
} from 'src/models/Offices/ListClientContactModel'
import {
  ClientWorkspaceModel,
  IClientWorkspaceListModel
} from 'src/models/Offices/ListClientWorkspaceModel'
import { WorkspaceFormData } from 'src/components/pages/offices/AddEditOfficeModal/OfficeWorkplace/OfficeEditWorkspaceDialog'
import { ContactInformationFormData } from 'src/components/pages/offices/AddEditOfficeModal/OfficeContactInformation/OfficeEditContactInformationForm'

export type ToolboxOptions =
  | ''
  | 'toprec'
  | 'gamification'
  | 'website'
  | 'talent_board'
  | 'ats_linked'
  | 'refari'

type ListParams = {
  ordering?: string
  search?: string
  page?: number
}

type SortConfig = {
  param: string
  direction: boolean // true means ascending order
}

type Filters = Partial<ListParams>

type Pagination = {
  totalRecordCount: number
  currentPage: number
  totalPageCount: number
  pageSize: number
}

export type SetPaginationParams = {
  totalRecordCount?: number
  currentPage?: number
  totalPageCount?: number
  pageSize?: number
}

export type IOfficeStore = {
  isFetchingClients: boolean
  offices: Array<IOfficeListModel>
  activeOfficeId: string
  pagination: Pagination
  shouldShowPagination: boolean
  sortConfig: SortConfig
  search: string
  isModalOpen: boolean
  fetchOffices(isForceRefreshCalculate?: boolean): Promise<void>
  createOffice: (data: {
    name: string
    logo?: number
    contacts: number[]
    primary_contact?: number
    workplace_addresses: number[]
    primary_workplace_address?: number
  }) => Promise<IOfficeListModel | null>
  updateOffice: (data: {
    name?: string
    logo?: number
    primary_contact?: number
    primary_workplace_address?: number
  }) => Promise<IOfficeListModel | null>
  setActiveOfficeId: (id: string) => void
  setModalOpen: (value: boolean) => void
  setPaginationParams: (paginationParams: SetPaginationParams) => void
  filterOffices: (filters: Filters) => Promise<void>
  sortList: (sortKey: string) => Promise<void>
  deleteOffice: (id: string) => Promise<void>
  getActiveOfficeDetails: () => IOfficeListModel | null
  fetchClientContacts: (currentPage: number) => Promise<{
    paginationParams: SetPaginationParams
    clientContactList: ClientContactModel[]
  } | null>
  createClientContact: (
    data: ContactInformationFormData
  ) => Promise<ClientContactModel | null>
  udpateClientContact: (
    data: ContactInformationFormData & { id: number },
    isPrimary: boolean
  ) => Promise<ClientContactModel | null>
  deleteClientContact: (id: number) => Promise<void>
  fetchClientWorkspaces: (currentPage: number) => Promise<{
    paginationParams: SetPaginationParams
    clientWorkspacesList: ClientWorkspaceModel[]
  } | null>
  createClientWorkspace: (
    data: any
  ) => Promise<IClientWorkspaceListModel | null>
  updateClientWorkspace: (
    data: WorkspaceFormData & { id: number },
    isPrimary: boolean
  ) => Promise<IClientWorkspaceListModel | null>
  deleteClientWorkspace: (id: number) => Promise<void>
}

class OfficeStore implements IOfficeStore {
  @observable isFetchingClients = false
  @observable offices: IOfficeStore['offices'] = []
  @observable activeOfficeId = ''
  @observable pagination: IOfficeStore['pagination'] = {
    totalRecordCount: 0,
    currentPage: 0,
    totalPageCount: 0,
    pageSize: 0
  }
  @observable sortConfig: IOfficeStore['sortConfig'] = {
    param: '',
    direction: false
  }
  @observable search = ''
  @observable isModalOpen = false

  @action
  private setIsFetchingClients = (value: boolean) => {
    this.isFetchingClients = value
  }

  @computed
  private get getParams(): ListParams {
    const params: ListParams = {}
    if (this.search) {
      params.search = this.search
    }

    if (this.sortConfig.param) {
      params.ordering = this.sortConfig.direction
        ? this.sortConfig.param
        : `-${this.sortConfig.param}`
    }

    if (this.pagination.currentPage) {
      params.page = this.pagination.currentPage
    }

    return params
  }

  @action
  setActiveOfficeId: IOfficeStore['setActiveOfficeId'] = (id: string) => {
    this.activeOfficeId = id
  }

  @action
  setModalOpen: IOfficeStore['setModalOpen'] = (value: boolean) => {
    this.isModalOpen = value
  }

  @action
  fetchOffices: IOfficeStore['fetchOffices'] = async (
    isForceRefreshCalculate
  ) => {
    try {
      this.setIsFetchingClients(true)

      const params = isForceRefreshCalculate
        ? { ...this.getParams, force_calculate: true }
        : this.getParams

      const response: APIResponse<ListResponse> = await API.getData(
        ApiRoutes.dashboard.offices.list,
        params
      )

      if (!response.data) {
        throw new Error("FetchClients API Response:: doesn't contain data")
      }

      const clientsResponseResults = response.data.results

      runInAction(() => {
        this.offices = clientsResponseResults.map(
          (item) => new ClientsListModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsFetchingClients(false)
    }
  }

  @action
  createOffice: IOfficeStore['createOffice'] = async (data) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.offices.list,
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error as any)
    }

    return null
  }

  @action
  updateOffice: IOfficeStore['updateOffice'] = async (data) => {
    try {
      const response = await API.putData(
        ApiRoutes.dashboard.offices.detail(this.activeOfficeId),
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error as any)
    }

    return null
  }

  @action
  filterOffices: IOfficeStore['filterOffices'] = async (filters) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchOffices()
  }

  @action
  setPaginationParams: IOfficeStore['setPaginationParams'] = ({
    totalRecordCount,
    currentPage,
    totalPageCount,
    pageSize
  }) => {
    if (totalRecordCount) {
      this.pagination.totalRecordCount = totalRecordCount
    }

    if (currentPage) {
      this.pagination.currentPage = currentPage
    }

    if (totalPageCount) {
      this.pagination.totalPageCount = totalPageCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  sortList: IOfficeStore['sortList'] = async (sortKey: string) => {
    this.pagination.currentPage = 1

    if (this.sortConfig.param === sortKey) {
      /**
       * change the direction
       */
      this.sortConfig = {
        param: sortKey,
        direction: !this.sortConfig.direction
      }
    } else {
      this.sortConfig = {
        param: sortKey,
        direction: false
      }
    }

    this.fetchOffices()
  }

  @action
  deleteOffice: IOfficeStore['deleteOffice'] = async (id) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.offices.detail(id)
      )
      if (response.status !== 204) throw new Error('something went wrong')
      toast.success(alertMessages.office.deleteSuccess)
      this.fetchOffices()
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong')
    }
  }

  @action
  getActiveOfficeDetails: IOfficeStore['getActiveOfficeDetails'] = () => {
    const office = this.offices.find(
      (item) => item.id === Number(this.activeOfficeId)
    )
    if (office) return office
    return null
  }

  fetchClientContacts: IOfficeStore['fetchClientContacts'] = async (
    currentPage: number
  ) => {
    try {
      const response = await API.getData(
        ApiRoutes.dashboard.offices.clientContactsList,
        {
          page: currentPage,
          ...(this.activeOfficeId && { company: this.activeOfficeId })
        }
      )
      return {
        clientContactList: response.data.results.map(
          (item: IClientContactListModel) => new ClientContactModel(item)
        ),
        paginationParams: {
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        }
      }
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  createClientContact: IOfficeStore['createClientContact'] = async (data) => {
    try {
      const formData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        is_primary: false,
        ...(this.activeOfficeId && { company: this.activeOfficeId })
      }
      const response = await API.postData(
        ApiRoutes.dashboard.offices.clientContactsList,
        formData
      )
      if (response.status === 201) {
        const newClientContact = new ClientContactModel(response.data)

        return newClientContact
      }
      return null
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  udpateClientContact: IOfficeStore['udpateClientContact'] = async (
    data,
    isPrimary
  ) => {
    try {
      const formData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        is_primary: isPrimary
      }
      const response = await API.putData(
        ApiRoutes.dashboard.offices.clientContactsDetail(data.id),
        formData
      )
      if (response.status === 200) {
        const updatedClientContact = new ClientContactModel(response.data)
        return updatedClientContact
      }
      return null
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  deleteClientContact: IOfficeStore['deleteClientContact'] = async (id) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.offices.clientContactsDetail(id)
      )
      if (response.status !== 204) throw new Error('something went wrong')
      toast.success(alertMessages.office.clientContact.deleteSuccess)
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong')
    }
  }

  fetchClientWorkspaces: IOfficeStore['fetchClientWorkspaces'] = async (
    currentPage: number
  ) => {
    try {
      const response = await API.getData(
        ApiRoutes.dashboard.offices.clientWorkspaceList,
        {
          page: currentPage,
          ...(this.activeOfficeId && { company: this.activeOfficeId })
        }
      )
      return {
        clientWorkspacesList: response.data.results.map(
          (item: IClientWorkspaceListModel) => new ClientWorkspaceModel(item)
        ),
        paginationParams: {
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        }
      }
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  createClientWorkspace: IOfficeStore['createClientWorkspace'] = async (
    data: WorkspaceFormData
  ) => {
    try {
      const formData = {
        name: data.address_tag,
        country: data.country,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        is_primary: false,
        ...(this.activeOfficeId && { company: this.activeOfficeId })
      }

      const response = await API.postData(
        ApiRoutes.dashboard.offices.clientWorkspaceList,
        formData
      )

      if (response.status === 201) {
        return new ClientWorkspaceModel(response.data)
      }

      return null
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  updateClientWorkspace: IOfficeStore['updateClientWorkspace'] = async (
    data,
    isPrimary
  ) => {
    try {
      const formData = {
        name: data.address_tag,
        country: data.country,
        city: data.city,
        state: data.state,
        postal_code: data.postal_code,
        is_primary: isPrimary
      }
      const response = await API.putData(
        ApiRoutes.dashboard.offices.clientWorkspaceDetail(data.id),
        formData
      )
      if (response.status === 200) {
        return new ClientWorkspaceModel(response.data)
      }
      return null
    } catch (error) {
      Logger.error(error as any)
      return null
    }
  }

  deleteClientWorkspace: IOfficeStore['deleteClientWorkspace'] = async (id) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.offices.clientWorkspaceDetail(id)
      )
      if (response.status !== 204) throw new Error('something went wrong')
      toast.success(alertMessages.office.clientWorkspace.deleteSuccess)
    } catch (error) {
      Logger.error(error as any)
      toast.error('something went wrong')
    }
  }

  @computed
  get shouldShowPagination(): boolean {
    return this.pagination.totalRecordCount > this.pagination.pageSize
  }
}

export default new OfficeStore()
