import { RefariDTOTypes } from '@refari-frontend/types'

type ListResponse =
  // @ts-ignore backend updates has broken the type
  RefariDTOTypes['/dashboard/company-clients/{company_client_id}/workplace-addresses/']['get']['responses']['200']['content']['application/json']

export type IClientWorkspaceListModel = ListResponse['results'][0]

export class ClientWorkspaceModel implements IClientWorkspaceListModel {
  id: IClientWorkspaceListModel['id']
  name: IClientWorkspaceListModel['name']
  city: IClientWorkspaceListModel['city']
  state: IClientWorkspaceListModel['state']
  country: IClientWorkspaceListModel['country']
  postal_code: IClientWorkspaceListModel['postal_code']
  is_primary: IClientWorkspaceListModel['is_primary']
  constructor(data: IClientWorkspaceListModel) {
    this.id = data.id
    this.name = data.name
    this.city = data.city
    this.state = data.state
    this.country = data.country
    this.postal_code = data.postal_code
    this.is_primary = data.is_primary
  }
}
