import React from 'react'
import PropTypes from 'prop-types'
import { Clear } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object
}

const defaultProps = {}

const CloseButton = ({ onClose, style }) => (
  <div className="modal-close-btn">
    <IconButton onClick={onClose} type="button" style={style}>
      <Clear />
    </IconButton>
  </div>
)

export default CloseButton
CloseButton.propTypes = propTypes
CloseButton.defaultProps = defaultProps
